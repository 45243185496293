import { Body5 } from '@cointracker/styleguide';
import { Sparkle, X } from '@phosphor-icons/react';
import * as Toast from '@radix-ui/react-toast';
import { cx } from 'class-variance-authority';
import { useContext, useState } from 'react';
import { UserContext } from 'src/app/user/context';

// Define a prefix for all localStorage keys to avoid collisions
const STORAGE_PREFIX = 'ct_toast_';

// Make this a reusable toast component with props for customization
export interface OfferToastProps {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
  showOnceWithKey?: string; // Key for localStorage to track if toast has been shown
  duration?: number; // How long the toast should stay visible
  className?: string;
  onOpenChange?: (open: boolean) => void;
  onAction?: () => void;
  actionText?: string;
}

export function OfferToast({
  title,
  description,
  icon = <Sparkle weight="regular" className="h-18 w-18 sm:h-24 sm:w-24" />,
  showOnceWithKey,
  duration = 600000,
  onOpenChange,
  onAction,
  actionText,
  className,
}: OfferToastProps) {
  const user = useContext(UserContext);
  const [shouldShowToast] = useState(() => {
    if (!showOnceWithKey) return true;

    const storageKey = `${STORAGE_PREFIX}${showOnceWithKey}`;

    if (localStorage.getItem(storageKey) !== 'true') {
      localStorage.setItem(storageKey, 'true');
      return true;
    }

    return false;
  });

  if (!user.isAuthenticated || !shouldShowToast) {
    return null;
  }

  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        className={cx(
          'flex h-fit items-stretch gap-12 rounded-8 p-16',
          className,
        )}
        onOpenChange={onOpenChange}
        duration={duration}
      >
        <div className="flex w-fit flex-1 items-stretch gap-12">
          <div className="hidden h-full shrink-0 items-center justify-center sm:flex">
            <div className="flex h-52 w-52 shrink-0 items-center justify-center rounded-4 bg-background-alternate-consistent">
              {icon}
            </div>
          </div>
          <div className="flex w-[280px] flex-col gap-4 sm:w-[255px] md:w-[280px]">
            {title && (
              <Toast.Title asChild>
                <Body5 className="inline-flex items-end gap-4 font-semibold !text-text-primary-consistent sm:!text-text-primary">
                  <span className="sm:hidden">{icon}</span>
                  {title}
                </Body5>
              </Toast.Title>
            )}
            {description && (
              <Toast.Description asChild>
                <Body5 className="!text-text-secondary">{description}</Body5>
              </Toast.Description>
            )}
            {actionText && (
              <Toast.Action
                className="text-xs rounded bg-primary px-2 py-1 font-medium text-secondary"
                asChild
                altText={actionText}
              >
                <button onClick={onAction}>{actionText}</button>
              </Toast.Action>
            )}
          </div>
          <Toast.Close className="mb-auto flex h-full flex-col text-text-primary-consistent sm:text-text-primary">
            <X weight="regular" className="h-18 w-18" />
          </Toast.Close>
        </div>
      </Toast.Root>

      <Toast.Viewport className="fixed bottom-48 left-1/2 z-[2147483647] m-0 flex max-w-full -translate-x-1/2 list-none flex-col gap-2 p-6 outline-none md:left-auto md:right-16 md:top-16 md:translate-x-0" />
    </Toast.Provider>
  );
}
